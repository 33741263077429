﻿import { Annotations } from '../globals.js';
import LineAnnotation from './lineAnnotation.js';

class RoomPerimeterLineAnnotation extends LineAnnotation {
    constructor() {
        super();
    }

    setContents(value) {
        super.setContents(value);
        this.setStartStyle(Annotations.LineEndType.NONE);
        this.setEndStyle(Annotations.LineEndType.NONE);
        this.caption = 'no';
    }
}

export default RoomPerimeterLineAnnotation;