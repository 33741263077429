﻿import { Tools, Annotations, defaultToolScale, defaultToolPrecision } from '../../globals.js'

class ScaleToolCreateTool extends Tools.DistanceMeasurementCreateTool {
    constructor(planViewer) {
        super(planViewer.docViewer);

        this.planViewer = planViewer;

        this.setDrawMode(Tools.LineCreateTool.DrawModes.TWO_CLICKS);
        this.setStyles({
            Scale: defaultToolScale,
            Precision: defaultToolPrecision,
            StrokeColor: new Annotations.Color(13, 114, 185),
        });

        this.addEventListener('annotationAdded', (annotation) => this.planViewer.pageScaleHandler.newPageScale(annotation));
        
    }

    mouseLeftDown(h) {
        super.mouseLeftDown(h);

        if (this.annotation) {
            this.annotation.setStartStyle(Annotations.LineEndType.BUTT);
            this.annotation.setEndStyle(Annotations.LineEndType.BUTT);
            this.annotation.caption = 'no';
            this.annotation.Listable = false;
        }
    }
}

export default ScaleToolCreateTool;