﻿import { Tools } from '../../globals.js'
import PolygonAnnotation from '../../Annotations/polygonAnnotation.js';
import BaseTool from '../BaseTool/base-tool.js';

class AreaCreateTool extends BaseTool(Tools.AreaMeasurementCreateTool) {
    constructor(planViewer) {
        super(planViewer);

        PolygonAnnotation.planViewer = this.planViewer;
        this.annotationPrototype = PolygonAnnotation;
    }
}

export default AreaCreateTool;