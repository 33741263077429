﻿import { Tools } from '../../globals.js'
import CountAnnotation from '../../Annotations/countAnnotation';
import { default as BaseTool, TRIGGER_MODES } from '../BaseTool/base-tool.js';

class CountCreateTool extends BaseTool(Tools.EllipseCreateTool) {
    constructor(planViewer) {
        super(planViewer);

        CountAnnotation.planViewer = this.planViewer;
        this.annotationPrototype = CountAnnotation;

        this.setDrawMode(Tools.EllipseCreateTool.DrawModes.TWO_CLICKS);
        this.setOnFinishTrigger(TRIGGER_MODES.ON_MOUSE_DOUBLE_CLICK);
    }

    onBeginAnnotation(annotation) {
        super.onBeginAnnotation(annotation);

        const size = Math.min(Math.max(25 / this.planViewer.getZoom(), 4), 25);

        var x = annotation.X - (size / 2);
        var y = annotation.Y - (size / 2);
        annotation.setWidth(size);
        annotation.setHeight(size);
        annotation.setX(x);
        annotation.setY(y);
        this.finish();
        this.annotationManager.drawAnnotationsFromList([annotation]);
        this.lastAnnotation = annotation;

        if (!this.showingTooltip) {
            this.showingTooltip = true;
        }
    }

    onFinishAnnotations(annotations) {
        super.onFinishAnnotations(annotations);
        this.showingTooltip = undefined;
        this.planViewer.hideTooltip();
    }

    onCancelAnnotations(annotations) {
        super.onCancelAnnotations(annotations);
        this.showingTooltip = undefined;
        this.planViewer.hideTooltip();
    }

    mouseDoubleClick(e) {
        if (this.lastAnnotation) {
            this.planViewer.annotManager.ungroupAnnotations([this.lastAnnotation]);
            this.annotationManager.deleteAnnotations([this.lastAnnotation]);
            this.lastAnnotation = null;
        }

        super.mouseDoubleClick(e);
    }

    mouseMove(e) {
        if (!this.annotation) {
            super.mouseMove(e);
        }

        if (this.showingTooltip) {
            this.planViewer.showTooltip("Tip", "Double click anywhere \nto finish your takeoff", e.clientX, e.clientY);
        }
    }

    switchIn(oldTool) {
        super.switchIn(oldTool);
        this.lastAnnotation = null;
    }

    switchOut(newTool) {
        super.switchOut(newTool);
        this.lastAnnotation = null;
    }
}

export default CountCreateTool;