﻿import { Annotations } from '../../globals.js'
import calculateMeasurementUnitsFromScale from '../../Helpers/calculateMeasurementUnitsFromScale.js';
import getMeasurementDepthAndWidth from '../../Helpers/getMeasurementDepthAndWidth.js';

export const TRIGGER_MODES = Object.freeze({ "ON_ANNOTATION_CREATE": 1, "ON_MOUSE_DOUBLE_CLICK": 2})

const BaseTool = toolClass => class extends toolClass {
    constructor(planViewer) {
        super(planViewer.docViewer);

        this.planViewer = planViewer;
        this.annotationManager = this.planViewer.annotManager;

        this.onAnnotationCreated = this.onAnnotationCreated.bind(this);
        this.onAnnotationAdded = this.onAnnotationAdded.bind(this);

        this.addEventListener('annotationCreated', this.onAnnotationCreated);
        this.addEventListener('annotationAdded', this.onAnnotationAdded);

        // Default trigger mode, only allows creating one add/subtract at a time
        this.setOnFinishTrigger(TRIGGER_MODES.ON_ANNOTATION_CREATE);

        // Set whether we should allow creating annotations on top of other annotations
        this.setShouldOverrideSelection(true);
        this.resetArgs();
    }

    setArgs({ mode, selectedAnnotationId }) {
        if (!mode || !selectedAnnotationId) {
            return;
        }

        this.mode = mode;

        this.groupLeaderAnnotation = this.annotationManager.getAnnotationById(selectedAnnotationId);
        this.createdFromAnnotation = this.groupLeaderAnnotation;

        if (!this.groupLeaderAnnotation || !this.createdFromAnnotation) {
            this.cancelAnnotations();
        }

        // If we're already in a group, make sure we select the leader
        const groupedAnnots = this.annotationManager.getGroupAnnotations(this.groupLeaderAnnotation);
        const ungroupedAnnots = groupedAnnots.filter(annot => !annot.isGrouped());
        this.groupLeaderAnnotation = ungroupedAnnots.length > 0 ? ungroupedAnnots[0] : this.groupLeaderAnnotation;

        this.annotationManager.deselectAnnotation(this.groupLeaderAnnotation);

        const currentFillColor = this.defaults.FillColor;
        const currentStrokeColor = this.defaults.StrokeColor;

        this.displayUnit = this.createdFromAnnotation.getCustomData('displayUnit');
        if (!this.displayUnit) {
            this.displayUnit = this.defaults.DefaultDisplayUnit;
        }

        this.previousStyles = {
            FillColor: new Annotations.Color(
                currentFillColor.R,
                currentFillColor.G,
                currentFillColor.B,
                currentFillColor.A),
            StrokeColor: new Annotations.Color(
                currentStrokeColor.R,
                currentStrokeColor.G,
                currentStrokeColor.B,
                currentStrokeColor.A)
        };

        let newFillColor = currentFillColor;
        let newStrokeColor = currentStrokeColor;
        let newStrokeThickness = 1;
        let newTextColour = this.defaults.TextColor;
        let newTextSize = this.defaults.FontSize;

        if (this.createdFromAnnotation.FillColor) {
            newFillColor = this.createdFromAnnotation.FillColor;
        }

        if (this.createdFromAnnotation.StrokeColor) {
            newStrokeColor = this.createdFromAnnotation.StrokeColor;
        }

        if (this.createdFromAnnotation.StrokeThickness) {
            newStrokeThickness = this.createdFromAnnotation.StrokeThickness;
        }

        if (this.createdFromAnnotation.TextColor) {
            newTextColour = this.createdFromAnnotation.TextColor;
        }

        if (this.createdFromAnnotation.FontSize) {
            newTextSize = this.createdFromAnnotation.FontSize;
        }

        if (this.mode === 'subtract') {
            this.setStyles({
                FillColor: new Annotations.Color(239, 68, 68, 0.25),
                StrokeColor: new Annotations.Color(239, 68, 68, 1),
                StrokeThickness: newStrokeThickness,
                FontSize: newTextSize,
                TextColor: new Annotations.Color(
                    newTextColour.R,
                    newTextColour.G,
                    newTextColour.B,
                    newTextColour.A),
            });
        } else {
            this.setStyles({
                FillColor: new Annotations.Color(
                    newFillColor.R,
                    newFillColor.G,
                    newFillColor.B,
                    newFillColor.A),
                StrokeColor: new Annotations.Color(
                    newStrokeColor.R,
                    newStrokeColor.G,
                    newStrokeColor.B,
                    newStrokeColor.A),
                StrokeThickness: newStrokeThickness,
                FontSize: newTextSize,
                TextColor: new Annotations.Color(
                    newTextColour.R,
                    newTextColour.G,
                    newTextColour.B,
                    newTextColour.A),
            });
        }

        this.groupLeaderAnnotation.Listable = false;
        this.createdFromAnnotation.Listable = false;
    }

    resetArgs() {
        this.planViewer.blockHover = false;
        this.mode = undefined;
        this.groupLeaderAnnotation = undefined;
        this.createdFromAnnotation = undefined;
        this.overrideSelection = this.shouldOverrideSelection;

        if (this.previousStyles) {
            this.setStyles({
                FillColor: this.previousStyles.FillColor,
                StrokeColor: this.previousStyles.StrokeColor,
            });
        }
        this.previousStyles = undefined;
        this.displayUnit = this.defaults.DefaultDisplayUnit;
    }

    setOnFinishTrigger(mode) {
        this.onFinishTrigger = mode;
    }

    getOnFinishTriggerMode() {
        return this.onFinishTrigger;
    }

    setShouldOverrideSelection(value) {
        this.shouldOverrideSelection = value;
    }

    getShouldOverrideSelection() {
        return this.shouldOverrideSelection;
    }

    finishAnnotations() {
        this.groupLeaderAnnotation.SkipName = false;
        this.groupLeaderAnnotation.Listable = true;

        if (this.createdFromAnnotation) {
            this.createdFromAnnotation.Listable = true;
        }

        const children = this.groupLeaderAnnotation.getGroupedChildren();
        const existingChildren = [];
        const newChildren = [];

        children.forEach((childAnnotation) => {
            if (!childAnnotation.Listable) {
                childAnnotation.Listable = true;
                newChildren.push(childAnnotation);
            } else {
                existingChildren.push(childAnnotation);
            }

            childAnnotation.adjustRect();
        });

        this.groupLeaderAnnotation.adjustRect();

        const modified = newChildren.concat([this.groupLeaderAnnotation]);
        if (this.mode) {
            this.onFinishAnnotations(newChildren);
            this.planViewer.annotManager.trigger('annotationChanged', [modified, 'modify', {}]);
        }
        else {
            this.onFinishAnnotations(modified);
            this.planViewer.annotManager.trigger('annotationChanged', [modified, 'add', {}]);
        }

        if (existingChildren && existingChildren.length > 0) {
            this.planViewer.annotManager.trigger('annotationChanged', [existingChildren, 'modify', {}]);
        }

        this.resetArgs();

        setTimeout(() => {
            this.planViewer.useDefaultTool();
        }, 0);
    }

    cancelAnnotations(switchToDefaultTool = true) {
        if (this.groupLeaderAnnotation) {

            if (this.mode) {
                this.groupLeaderAnnotation.Listable = true;

                if (this.createdFromAnnotation) {
                    this.createdFromAnnotation.Listable = true;
                }
            }

            const children = this.groupLeaderAnnotation.getGroupedChildren();

            let childrenToDelete = [];

            children.forEach((childAnnotation) => {
                if (!childAnnotation.Listable) {
                    childrenToDelete.push(childAnnotation);
                }
            });

            if (!this.groupLeaderAnnotation.Listable) {
                childrenToDelete.push(this.groupLeaderAnnotation);
            }

            if (childrenToDelete && childrenToDelete.length > 0) {
                this.onCancelAnnotations(childrenToDelete);

                this.planViewer.annotManager.ungroupAnnotations(childrenToDelete);
                this.planViewer.annotManager.deleteAnnotations(childrenToDelete);
            }
        }

        this.resetArgs();

        if (switchToDefaultTool) {
            setTimeout(() => {
                this.planViewer.useDefaultTool();
            }, 0);
        }
    }

    switchIn(oldTool) {
        super.switchIn(oldTool);
        this.resetArgs();
    }

    switchOut(newTool) {
        super.switchOut(newTool);
        this.cancelAnnotations(false);
    }

    onFinishAnnotations(annotations) {
        // Override this to mutate the annotation before its finalised
    }

    onCancelAnnotations(annotations) {
        // Override this to handle canceled annotations
    }

    onBeginAnnotation(annotation) {
        // Override this to mutate the annotation when it starts
    }

    onAnnotationCreated(annotation) {
        this.planViewer.blockHover = true;

        annotation.SkipName = true;
        annotation.Listable = false;

        if (this.groupLeaderAnnotation && this.groupLeaderAnnotation !== annotation) {
            annotation.Subject = this.groupLeaderAnnotation.Subject;
        } else {
            this.groupLeaderAnnotation = annotation;
        }

        this.onBeginAnnotation(annotation);
    }

    onAnnotationAdded(annotation) {
        if (this.displayUnit) {
            const convertedDisplayUnit = calculateMeasurementUnitsFromScale(annotation, this.displayUnit);

            if (convertedDisplayUnit) {
                annotation.setCustomData('displayUnit', convertedDisplayUnit);
            }
        }

        if (this.createdFromAnnotation && this.createdFromAnnotation !== annotation) {
            if (this.mode === 'subtract') {
                // The measure dict isn't created yet in onAnnotationCreated so we have to do it here
                annotation.Measure.area[0].factor *= -1;
                annotation.Measure.distance[0].factor *= -1;
            }

            const { depth, width } = getMeasurementDepthAndWidth(this.createdFromAnnotation);
            if (depth || width) {
                this.planViewer.setAnnotationDimensions(annotation, depth, width);
            }
        }

        if (this.groupLeaderAnnotation && this.groupLeaderAnnotation !== annotation) {
            this.planViewer.annotManager.groupAnnotations(this.groupLeaderAnnotation, [annotation]);
        }

        if (this.onFinishTrigger == TRIGGER_MODES.ON_ANNOTATION_CREATE) {
            this.finishAnnotations();
        }
    }

    mouseDoubleClick(e) {
        super.mouseDoubleClick(e);

        if (this.onFinishTrigger == TRIGGER_MODES.ON_MOUSE_DOUBLE_CLICK) {
            this.finishAnnotations();
        }
    }

    mouseLeftDown(e) {
        super.mouseLeftDown(e);
    }

    mouseMove(e) {
        super.mouseMove(e);
    }

    keyDown(e) {
        super.keyDown(e);

        if (e.code === "Escape") {
            this.cancelAnnotations();
        }
    }    
}

export default BaseTool;