﻿export default function getMeasurementDepthAndWidth(annotation) {
    let depth = null;
    let width = null;

    if (!annotation
        || !(annotation instanceof Annotations.MarkupAnnotation)
        || !annotation.ToolName) {
        return { depth, width };
    }

    if (annotation.ToolName.includes('PerimeterArea')) {
        depth = Math.abs(annotation.Measure?.distance[0]?.factor);
        if (!depth || isNaN(depth)) {
            depth = null;
        }

    } else if (annotation.ToolName.includes('Volume')) {
        depth = Math.abs(annotation.Measure?.area[0]?.factor);
        if (!depth || isNaN(depth)) {
            depth = null;
        }

        if (annotation.ToolName.includes('PerimeterVolume')) {
            width = Math.abs(annotation.Measure?.distance[0]?.factor);
            if (!width || isNaN(width)) {
                width = null;
            }
        }
    }

    return { depth, width };
}