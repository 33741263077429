﻿import { Tools } from '../../globals.js'
import RoomPerimeterLineAnnotation from '../../Annotations/roomPerimeterLineAnnotation.js';
import { default as BaseTool, TRIGGER_MODES } from '../BaseTool/base-tool.js';

class RoomPerimeterCreateTool extends BaseTool(Tools.DistanceMeasurementCreateTool) {
    constructor(planViewer) {
        super(planViewer);

        RoomPerimeterLineAnnotation.planViewer = this.planViewer;
        this.annotationPrototype = RoomPerimeterLineAnnotation;

        this.setDrawMode(Tools.LineCreateTool.DrawModes.TWO_CLICKS);

        this.setOnFinishTrigger(TRIGGER_MODES.ON_MOUSE_DOUBLE_CLICK);
    }

    onBeginAnnotation(annotation) {
        super.onBeginAnnotation(annotation);

        if (!this.showingTooltip) {
            this.showingTooltip = true;
        }
    }

    onFinishAnnotations(annotations) {
        super.onFinishAnnotations(annotations);
        this.showingTooltip = undefined;
        this.planViewer.hideTooltip();
    }

    onCancelAnnotations(annotations) {
        super.onCancelAnnotations(annotations);
        this.showingTooltip = undefined;
        this.planViewer.hideTooltip();
    }

    mouseMove(e) {
        super.mouseMove(e);

        if (this.showingTooltip) {
            this.planViewer.showTooltip("Tip", "Double click anywhere \nto finish your takeoff", e.clientX, e.clientY);
        }
    }
}

export default RoomPerimeterCreateTool;