﻿export const Tools = window.Tools;
export const Core = window.CoreControls;
export const Annotations = window.Annotations;

export const defaultToolScale = [[1, 'mm'], [100, 'mm']];
export const defaultToolDisplayUnit = 'm';
export const defaultToolPrecision = 0.01;

export const PRECISION_MAP = {
    "0": 0,
    "0.1": 1,
    "0.01": 2,
    "0.001": 3,
    "0.0001": 4,
}

export const UNITS = {
    length: ['mm', 'cm', 'm', 'km', 'mi', 'yd', 'ft', 'in'],
    area: ['mm2', 'cm2', 'm2', 'km2', 'mi2', 'yd2', 'ft2', 'in2'],
    volume: ['mm3', 'cm3', 'm3', 'km3', 'mi3', 'yd3', 'ft3', 'in3'],
}

export const MEASUREMENT_TOOL_NAMES = [
    Tools.ToolNames['PAN'],
    Tools.ToolNames['DISTANCE_MEASUREMENT'],
    Tools.ToolNames['PERIMETER_MEASUREMENT'],
    Tools.ToolNames['AREA_MEASUREMENT'],
    Tools.ToolNames['RECTANGULAR_AREA_MEASUREMENT'],
    Tools.ToolNames['COUNT_MEASUREMENT'],
    Tools.ToolNames['CALLOUT'],
    'AnnotationSetScaleMeasurement',
    'AnnotationCreateRoomPerimeterMeasurement',
    'AnnotationCreateEllipseAreaMeasurement',
    'AnnotationCreatePerimeterAreaMeasurement',
    'AnnotationCreateRoomPerimeterAreaMeasurement',
    'AnnotationCreateVolumeMeasurement',
    'AnnotationCreateRectangularVolumeMeasurement',
    'AnnotationCreateEllipseVolumeMeasurement',
    'AnnotationCreatePerimeterVolumeMeasurement',
    'AnnotationCreateRoomPerimeterVolumeMeasurement',
];

Core.setWorkerPath('./js/PlanViewer/lib/core');
Core.setResourcesPath('./js/PlanViewer/lib/core/assets');