﻿import { Tools } from '../../globals.js'
import PolygonAnnotation from '../../Annotations/polygonAnnotation.js';
import BaseTool from '../BaseTool/base-tool.js';

class RectangularAreaCreateTool extends BaseTool(Tools.RectangularAreaMeasurementCreateTool) {
    constructor(planViewer) {
        super(planViewer);

        PolygonAnnotation.planViewer = this.planViewer;
        this.annotationPrototype = PolygonAnnotation;

        this.setDrawMode(Tools.LineCreateTool.DrawModes.TWO_CLICKS);
    }
}

export default RectangularAreaCreateTool;