﻿import { Tools } from '../../globals.js'
import PolylineAnnotation from '../../Annotations/polylineAnnotation.js';
import BaseTool from '../BaseTool/base-tool.js';

class PerimeterCreateTool extends BaseTool(Tools.PerimeterMeasurementCreateTool) {
    constructor(planViewer) {
        super(planViewer);

        PolylineAnnotation.planViewer = this.planViewer;
        this.annotationPrototype = PolylineAnnotation;
    }

    onBeginAnnotation(annotation) {
        super.onBeginAnnotation(annotation);

        if (!this.showingTooltip) {
            this.showingTooltip = true;
        }
    }

    onFinishAnnotations(annotations) {
        super.onFinishAnnotations(annotations);
        this.showingTooltip = undefined;
        this.planViewer.hideTooltip();
    }

    onCancelAnnotations(annotations) {
        super.onCancelAnnotations(annotations);
        this.showingTooltip = undefined;
        this.planViewer.hideTooltip();
    }

    mouseMove(e) {
        super.mouseMove(e);

        if (this.showingTooltip) {
            this.planViewer.showTooltip("Tip", "Double click anywhere \nto finish your takeoff", e.clientX, e.clientY);
        }
    }
}

export default PerimeterCreateTool;