﻿import { Annotations } from '../globals.js'
import calculateMeasurementContents from '../Helpers/calculateMeasurementContents.js'

class PolygonAnnotation extends Annotations.PolygonAnnotation {
    static planViewer;

    constructor() {
        super();
    }

    setContents(value) {
        super.setContents(calculateMeasurementContents(this, this.constructor.planViewer.annotManager, value));
    }
}

export default PolygonAnnotation;