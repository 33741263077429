﻿import getMeasurementDepthAndWidth from './getMeasurementDepthAndWidth.js';
import calculateMeasurementUnitsFromScale from './calculateMeasurementUnitsFromScale.js';

export default function getAnnotationExportData(annotation) {
    if (!annotation || !(annotation instanceof Annotations.MarkupAnnotation)) {
        return null;
    }

    const { depth, width } = getMeasurementDepthAndWidth(annotation);

    const units = calculateMeasurementUnitsFromScale(annotation);
    const displayUnits = annotation.getCustomData('displayUnit') ?? units;

    let strokeColourHex = annotation.StrokeColor?.toHexString();
    let strokeColourOpacity = parseFloat(annotation.getCustomData('strokeOpacity'));
    let stroke = parseFloat(annotation.StrokeThickness);
    let fillColourHex = annotation.FillColor?.toHexString();
    let fillColourOpacity = parseFloat(annotation.getCustomData('fillOpacity'));
    let textColourHex = annotation.TextColor?.toHexString();
    let textSize = annotation.FontSize;

    if (annotation.ToolName.includes('Perimeter')
        || annotation.ToolName.includes('Count')
        || annotation.ToolName.includes('Distance')) {
        fillColourHex = null;
        fillColourOpacity = null;
    }

    return {
        id: annotation.Id,
        name: annotation.Subject,
        page: annotation.getPageNumber(),
        type: annotation["_xsi:type"],
        toolName: annotation.ToolName,
        isVisible: annotation.isVisible(),
        strokeColourHex: strokeColourHex,
        strokeColourOpacity: strokeColourOpacity,
        stroke: stroke,
        fillColourHex: fillColourHex,
        fillColourOpacity: fillColourOpacity,
        textColourHex: textColourHex,
        textSize: textSize,
        groupId: annotation.InReplyTo,
        isMultiPageGroupLeader: annotation.getCustomData('groupIsMultiPage') === 'true' ? true : false,
        contents: annotation.getContents(),
        groupContents: annotation.getCustomData('groupContents'),
        groupMeasurement: parseFloat(annotation.getCustomData('groupMeasurement')),
        measurement: parseFloat(annotation.getCustomData('measurement')),
        measurementWidth: width,
        measurementDepth: depth,
        measurementUnits: units,
        measurementDisplayUnits: displayUnits,
    };
}