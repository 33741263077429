﻿import { Tools } from '../../globals.js'
import BaseTool from '../BaseTool/base-tool.js';

class CalloutTextCreateTool extends BaseTool(Tools.CalloutCreateTool) {
    constructor(planViewer) {
        super(planViewer);

        this.setStyles({
            Width: 100,
            Height: 200,
        });

        //this.enableAutoSizedTextBox(); BROKEN
    }
}

export default CalloutTextCreateTool;