﻿// Core Imports
import './lib/core/webviewer-core.min.js';
import { Core, Annotations, Tools, MEASUREMENT_TOOL_NAMES, defaultToolScale, defaultToolPrecision } from './globals.js';
import PageScaleHandler from './Handlers/page-scale-handler.js';
import EventHandler from './Handlers/event-handler.js';

// Helpers
import getAnnotationExportData from './Helpers/getAnnotationExportData.js';
import getMeasurementDepthAndWidth from './Helpers/getMeasurementDepthAndWidth.js';

// Annotation Imports
import EllipseAnnotation from './Annotations/ellipseAnnotation.js';
import LineAnnotation from './Annotations/lineAnnotation.js';
import PolygonAnnotation from './Annotations/polygonAnnotation.js';
import PolylineAnnotation from './Annotations/polylineAnnotation.js';
import RoomPerimeterLineAnnotation from './Annotations/roomPerimeterLineAnnotation.js';
import CountAnnotation from './Annotations/countAnnotation.js';

// Tool Imports

// Pan Tool
import PanTool from './Tools/PanTool/pan-tool.js';

// Scale Tool
import ScaleToolCreateTool from './Tools/ScaleTool/scaletool-create-tool.js';

// Distance Tools
import RoomPerimeterCreateTool from './Tools/RoomPerimeterTool/roomperimeter-create-tool.js';
import PerimeterCreateTool from './Tools/PerimeterTool/perimeter-create-tool.js';

// Area Tools
import AreaCreateTool from './Tools/AreaTool/area-create-tool.js';
import RectangularAreaCreateTool from './Tools/RectangularAreaTool/rectangular-area-create-tool.js';
import EllipsesAreaCreateTool from './Tools/EllipsesAreaTool/ellipses-area-create-tool.js';
import PerimeterAreaCreateTool from './Tools/PerimeterAreaTool/perimeter-area-create-tool.js';
import RoomPerimeterAreaCreateTool from './Tools/RoomPerimeterAreaTool/roomperimeter-area-create-tool.js';

// Volume Tools
import VolumeCreateTool from './Tools/VolumeTool/volume-create-tool.js';
import RectangularVolumeCreateTool from './Tools/RectangularVolumeTool/rectangular-volume-create-tool.js';
import EllipsesVolumeCreateTool from './Tools/EllipsesVolumeTool/ellipses-volume-create-tool.js';
import PerimeterVolumeCreateTool from './Tools/PerimeterVolumeTool/perimeter-volume-create-tool.js';
import RoomPerimeterVolumeCreateTool from './Tools/RoomPerimeterVolumeTool/roomperimeter-volume-create-tool.js';

// Markup Tools
import CountCreateTool from './Tools/CountTool/count-create-tool.js';
import DistanceCreateTool from './Tools/DistanceTool/distance-create-tool.js';
import CalloutTextCreateTool from './Tools/CalloutTextTool/callout-text-tool.js';

export class PlanViewer {
    constructor(callbackRef, scrollViewElement, documentElement) {
        this.scrollViewElement = scrollViewElement;
        this.documentElement = documentElement;

        Core.enableFullPDF();

        this.docViewer = new Core.DocumentViewer();
        this.docViewer.setScrollViewElement(this.scrollViewElement);
        this.docViewer.setViewerElement(this.documentElement);
        this.docViewer.enableAnnotations();
        this.doc = this.docViewer.getDocument();
        this.thumbnailCache = {};
        this.dirtyAnnotations = {};

        this.annotManager = this.docViewer.getAnnotationManager();

        this.setupTools();
        this.registerAnnotations();

        const displayMode = new Core.DisplayMode(
            this.docViewer,
            Core.DisplayModes.Single,
            true);
        this.setDisplayMode(displayMode);

        this.pageScaleHandler = new PageScaleHandler(this);
        this.eventHandler = new EventHandler(this, callbackRef);

        this.useDefaultTool();
    }

    dispose() {
        this.thumbnailCache = undefined;
        this.dirtyAnnotations = undefined;

        this.pageScaleHandler.dispose();
        this.eventHandler.dispose();
        this.docViewer.dispose();
        this.disposed = true;
    }

    exportAnnotationsRaw() {
        return this.annotManager.exportAnnotations({ links: false, widgets: false, fields: false }).then(xfdfData => {
            return xfdfData;
        });
    }

    exportAnnotations() {
        return this.annotManager.exportAnnotations({ links: false, widgets: false, fields: false }).then(xfdfData => {
            let annotationsData = [];
            const annotations = this.annotManager.getAnnotationsList();
            annotations.forEach((annotation) => {
                if (annotation instanceof Annotations.MarkupAnnotation
                    && !annotation.getCustomData("IsScaleAnnotation")
                    && annotation.Listable
                    && annotation.Subject) {
                    annotationsData.push(getAnnotationExportData(annotation));
                }
            });

            return { rawAnnotations: xfdfData, annotations: annotationsData };
        });
    }

    loadDocument(path, xfdfString, startInReadOnlyMode, annotationNameHint, pageRotations) {
        this.initialXFDFString = xfdfString;
        this.setReadOnlyMode(startInReadOnlyMode);
        this.defaultNewAnnotationName = annotationNameHint;
        if (!this.defaultNewAnnotationName) {
            this.defaultNewAnnotationName = null;
        }
        this.initialPageRotations = pageRotations;

        this.docViewer.loadDocument(path, {
            l: 'Work Smarter Enterprises Pty Ltd (quotespec.com):OEM:QuoteSpec::B+:AMS(20230621):5EA5BB9C04A7080AF360B13AC9A2537860616F9BFF585A558B42AE941A443CCE64EA31F5C7',
            extension: 'pdf',
        });
    }

    setupTools() {
        const panTool = {
            toolName: 'Pan',
            toolLookupName: 'PAN',
            toolObject: new PanTool(this),
        };

        const scaleTool = {
            toolName: 'AnnotationSetScaleMeasurement',
            toolLookupName: 'SCALE_MEASUREMENT',
            toolObject: new ScaleToolCreateTool(this),
        };

        const roomPerimeterTool = {
            toolName: 'AnnotationCreateRoomPerimeterMeasurement',
            toolLookupName: 'ROOM_PERIMETER_MEASUREMENT',
            toolObject: new RoomPerimeterCreateTool(this),
        }

        const perimeterTool = {
            toolName: 'AnnotationCreatePerimeterMeasurement',
            toolLookupName: 'PERIMETER_MEASUREMENT',
            toolObject: new PerimeterCreateTool(this),
        }

        const areaTool = {
            toolName: 'AnnotationCreateAreaMeasurement',
            toolLookupName: 'AREA_MEASUREMENT',
            toolObject: new AreaCreateTool(this),
        }

        const rectangularAreaTool = {
            toolName: 'AnnotationCreateRectangularAreaMeasurement',
            toolLookupName: 'RECTANGULAR_AREA_MEASUREMENT',
            toolObject: new RectangularAreaCreateTool(this),
        }

        const ellipsesAreaTool = {
            toolName: 'AnnotationCreateEllipseAreaMeasurement',
            toolLookupName: 'ELLIPSE_MEASUREMENT',
            toolObject: new EllipsesAreaCreateTool(this),
        }

        const perimeterAreaTool = {
            toolName: 'AnnotationCreatePerimeterAreaMeasurement',
            toolLookupName: 'PERIMETER_AREA_MEASUREMENT',
            toolObject: new PerimeterAreaCreateTool(this),
        }

        const roomPerimeterAreaTool = {
            toolName: 'AnnotationCreateRoomPerimeterAreaMeasurement',
            toolLookupName: 'ROOM_PERIMETER_AREA_MEASUREMENT',
            toolObject: new RoomPerimeterAreaCreateTool(this),
        }

        const volumeTool = {
            toolName: 'AnnotationCreateVolumeMeasurement',
            toolLookupName: 'VOLUME_MEASUREMENT',
            toolObject: new VolumeCreateTool(this),
        }

        const rectangulaVolumeTool = {
            toolName: 'AnnotationCreateRectangularVolumeMeasurement',
            toolLookupName: 'RECTANGULAR_VOLUME_MEASUREMENT',
            toolObject: new RectangularVolumeCreateTool(this),
        }

        const ellipsesVolumeTool = {
            toolName: 'AnnotationCreateEllipseVolumeMeasurement',
            toolLookupName: 'ELLIPSE_VOLUME_MEASUREMENT',
            toolObject: new EllipsesVolumeCreateTool(this),
        }

        const perimeterVolumeTool = {
            toolName: 'AnnotationCreatePerimeterVolumeMeasurement',
            toolLookupName: 'PERIMETER_VOLUME_MEASUREMENT',
            toolObject: new PerimeterVolumeCreateTool(this),
        }

        const roomPerimeterVolumeTool = {
            toolName: 'AnnotationCreateRoomPerimeterVolumeMeasurement',
            toolLookupName: 'ROOM_PERIMETER_VOLUME_MEASUREMENT',
            toolObject: new RoomPerimeterVolumeCreateTool(this),
        }

        const countTool = {
            toolName: 'AnnotationCreateCountMeasurement',
            toolLookupName: 'COUNT_MEASUREMENT',
            toolObject: new CountCreateTool(this),
        }

        const distanceTool = {
            toolName: 'AnnotationCreateDistanceMeasurement',
            toolLookupName: 'DISTANCE_MEASUREMENT',
            toolObject: new DistanceCreateTool(this),
        }

        const textCalloutTool = {
            toolName: 'AnnotationCreateCallout',
            toolLookupName: 'CALLOUT',
            toolObject: new CalloutTextCreateTool(this),
        }

        this.registerTool(panTool);
        this.registerTool(scaleTool);

        this.registerTool(roomPerimeterTool);
        this.registerTool(perimeterTool);

        this.registerTool(areaTool);
        this.registerTool(rectangularAreaTool);
        this.registerTool(ellipsesAreaTool);
        this.registerTool(perimeterAreaTool);
        this.registerTool(roomPerimeterAreaTool);

        this.registerTool(volumeTool);
        this.registerTool(rectangulaVolumeTool);
        this.registerTool(ellipsesVolumeTool);
        this.registerTool(perimeterVolumeTool);
        this.registerTool(roomPerimeterVolumeTool);

        this.registerTool(countTool);
        this.registerTool(distanceTool);
        this.registerTool(textCalloutTool);

        this.setDefaultToolStyles();
    }

    registerTool({ toolObject, toolName, toolLookupName }) {
        const toolModeMap = this.docViewer.getToolModeMap();

        toolModeMap[toolName] = toolObject;
        toolModeMap[toolName].name = toolName;

        Tools.ToolNames[toolLookupName] = toolName;
    }

    setDefaultToolStyles() {
        let tools = [];
        tools = MEASUREMENT_TOOL_NAMES.map((t) => this.getTool(t));

        tools.forEach(tool => {
            if (tool.setStyles && tool.name !== 'Pan') {
                tool.setStyles({
                    Scale: defaultToolScale,
                    Precision: defaultToolPrecision,
                    StrokeColor: new Annotations.Color(59, 130, 246),
                    FillColor: new Annotations.Color(34, 197, 94, 0.25),
                    TextColor: new Annotations.Color(0, 0, 0, 1),
                    FontSize: "12pt",
                    Opacity: 1,
                });
            }
        });

        this.setEnableSnapMode(false);
    }

    setTool(toolName, args) {
        const tool = this.getTool(toolName);
        if (!tool) {
            this.useDefaultTool();
            return;
        }

        this.eventHandler.onToolChange(toolName);
        this.docViewer.setToolMode(tool);

        // Used for additional functionality like add/subtract
        if (args && tool.setArgs) {
            tool.setArgs(args);
        }
    }

    useDefaultTool() {
        this.setTool("Pan");
    }

    getTool(toolName) {
        return this.docViewer.getTool(toolName);
    }

    getCurrentTool() {
        return this.docViewer.getToolMode();
    }

    setEnableSnapMode(enableSnapping) {
        if (enableSnapping) {
            this.setToolSnapMode(this.docViewer.SnapMode.DEFAULT);
        } else {
            this.setToolSnapMode(null);
        }
    }

    setToolSnapMode(snapMode) {
        let tools = [];
        tools = MEASUREMENT_TOOL_NAMES.map((t) => this.getTool(t));

        tools.forEach(tool => {
            if (tool.setSnapMode) {
                tool.setSnapMode(snapMode);
            }
        });
    }

    isSnapModeEnabled() {
        const tool = this.getTool("AnnotationSetScaleMeasurement");
        return tool.getSnapMode() === this.docViewer.SnapMode.DEFAULT;
    }

    registerAnnotations() {
        EllipseAnnotation.prototype.elementName = "ellipsemeasure";
        LineAnnotation.prototype.elementName = "linemeasure";
        PolygonAnnotation.prototype.elementName = "polygonmeasure";
        PolylineAnnotation.prototype.elementName = "polylinemeasure";
        RoomPerimeterLineAnnotation.prototype.elementName = "roomperimetermeasure";
        CountAnnotation.prototype.elementName = "countmeasure";

        this.annotManager.registerAnnotationType(EllipseAnnotation.prototype.elementName, EllipseAnnotation);
        this.annotManager.registerAnnotationType(LineAnnotation.prototype.elementName, LineAnnotation);
        this.annotManager.registerAnnotationType(PolygonAnnotation.prototype.elementName, PolygonAnnotation);
        this.annotManager.registerAnnotationType(PolylineAnnotation.prototype.elementName, PolylineAnnotation);
        this.annotManager.registerAnnotationType(RoomPerimeterLineAnnotation.prototype.elementName, RoomPerimeterLineAnnotation);
        this.annotManager.registerAnnotationType(CountAnnotation.prototype.elementName, CountAnnotation);
    }

    refreshScrollView() {
        const scrollViewElement = this.docViewer.getScrollViewElement();
        this.docViewer.setScrollViewElement(scrollViewElement);
        this.docViewer.scrollViewUpdated();
    }

    setDisplayMode(displayMode) {
        this.docViewer.getDisplayModeManager().setDisplayMode(displayMode);
    }

    setReadOnlyMode(enable) {
        if (enable) {
            this.annotManager.enableReadOnlyMode();
        } else {
            this.annotManager.disableReadOnlyMode();
        }
    }

    zoomToMouse(zoomFactor) {
        this.docViewer.zoomToMouse(zoomFactor, 0, 0);
    }

    getZoom() {
        return this.docViewer.getZoomLevel();
    }

    fitToPage() {
        this.docViewer.setFitMode(this.docViewer.FitMode.FitPage);
    }

    fitToWidth() {
        this.docViewer.setFitMode(this.docViewer.FitMode.FitWidth);
    }

    getCurrentPage() {
        return this.docViewer.getCurrentPage();
    }

    setCurrentPage(page) {
        this.useDefaultTool();
        this.docViewer.setCurrentPage(Math.min(Math.max(page, 1), this.getPageCount()));
    }

    getPageCount() {
        return this.docViewer.getPageCount();
    }

    rotatePage(pageNumber, rotation) {
        return this.docViewer.getDocument().rotatePages([pageNumber], rotation).then(() => {
            if (this.thumbnailCache[pageNumber]) {
                // Invalidate the thumbnail
                this.thumbnailCache[pageNumber] = null;
            }

            this.eventHandler.onPageRotate(pageNumber, rotation);
        });
    }

    getPageRotation(pageNumber) {
        return this.docViewer.getDocument().getPageRotation(pageNumber);
    }

    renderThumbnail(pageNumber) {
        const elementId = 'thmb-' + pageNumber;
        if (this.thumbnailCache[pageNumber]) {
            const thumbnailContainer = document.getElementById(elementId);
            if (!thumbnailContainer) {
                return;
            }

            thumbnailContainer.innerHTML = "";
            thumbnailContainer.appendChild(this.thumbnailCache[pageNumber]);
            return;
        }

        this.docViewer.getDocument().
            loadThumbnail(pageNumber, (thumbnail) => {
                this.thumbnailCache[pageNumber] = thumbnail;

                const thumbnailContainer = document.getElementById(elementId);
                if (!thumbnailContainer) {
                    return;
                }

                thumbnailContainer.innerHTML = "";
                thumbnailContainer.appendChild(this.thumbnailCache[pageNumber]);
            });
    }

    setDirtyAnnotation(operation, annotation) {
        if (!operation || !annotation) {
            return;
        }

        if (this.initialXFDFString) {
            // Havent finished importing!
            return;
        }

        const existingState = this.dirtyAnnotations[annotation.Id];
        if (existingState && existingState.operation === 'delete') {
            // Nothing should overide delete states
            return;
        }

        this.dirtyAnnotations[annotation.Id] = { operation: operation, annotation: getAnnotationExportData(annotation) };
        this.eventHandler.marshallDirtyAnnotationsDebounceWrapper();
    }

    assertValidAnnotation(annotation, setName = false, setWidth = false, setDepth = false) {
        if (!annotation || annotation.getCustomData("IsScaleAnnotation")) {
            return;
        }

        const { depth, width } = getMeasurementDepthAndWidth(annotation);

        const nameRequired = !annotation.Subject;
        const depthRequired = annotation.ToolName && (annotation.ToolName.includes('Volume') || annotation.ToolName.includes('PerimeterArea')) && !depth;
        const widthRequired = depthRequired && annotation.ToolName && annotation.ToolName.includes('PerimeterVolume') && !width;

        const isInvalid = (nameRequired && !setName)
            || (depthRequired && !setDepth)
            || (widthRequired && !setWidth);

        const annotations = this.annotManager.getGroupAnnotations(annotation);

        if (isInvalid) {
            this.annotManager.deleteAnnotations(annotations);
            return false;
        }

        return true;
    }

    updateAnnotationById(id, args) {
        const properties = Object.assign(...args);
        let { name, measurementDepth, measurementWidth } = properties;
        let { strokeColourHex, stroke, fillColourHex, textColourHex, textSize, measurementDisplayUnits } = properties;
        let { measurementSign } = properties;

        const annotation = this.annotManager.getAnnotationById(id);
        if (!annotation) {
            return;
        }

        if (!this.assertValidAnnotation(annotation, name, measurementWidth, measurementDepth)) {
            return;
        }

        const { depth, width } = getMeasurementDepthAndWidth(annotation);
        const annotations = this.annotManager.getGroupAnnotations(annotation);

        if (name) {
            const otherAnnotationGroupLeader = this.annotManager.getAnnotationsList().filter(annot => !annotations.includes(annot) && annot.Subject === name && !annot.isGrouped())[0];
            if (otherAnnotationGroupLeader) {
                const oldGroupAnnots = this.annotManager.getGroupAnnotations(otherAnnotationGroupLeader);
                this.annotManager.groupAnnotations(otherAnnotationGroupLeader, annotations);

                const annotData = getAnnotationExportData(otherAnnotationGroupLeader);
                strokeColourHex = annotData.strokeColourHex;
                stroke = annotData.stroke;
                fillColourHex = annotData.fillColourHex;
                textColourHex = annotData.textColourHex;
                textSize = annotData.textSize;
                measurementDisplayUnits = annotData.measurementDisplayUnits;

                const newGroupAnnots = this.annotManager.getGroupAnnotations(otherAnnotationGroupLeader);
                newGroupAnnots.forEach((newGroupedAnnot) => {
                    newGroupedAnnot.adjustRect();
                });

                this.annotManager.trigger('annotationChanged', [oldGroupAnnots, 'modify', {}]);
            }
        }

        // When setting the palette we make sure the properties are set correctly
        // Then we only set the styles of annotations currently selected and unlocked
        const setPalette = (strokeColourHex || fillColourHex || stroke || textColourHex || textSize);
        const shouldSetPalette = (annot) => setPalette
            && this.annotManager.isAnnotationSelected(annot)
            && !annot.Locked;

        const shouldSetMeasurementSign = (annot) => measurementSign
            && annot === annotation;

        annotations.forEach((annot) => {

            if (name) {
                annot.Subject = name;
            }

            if (measurementDepth || measurementWidth) {
                if (!measurementDepth) {
                    measurementDepth = depth;
                }

                if (!measurementWidth) {
                    measurementWidth = width;
                }

                this.setAnnotationDimensions(annot, measurementDepth, measurementWidth);
            }

            if (shouldSetPalette(annot)) {
                const fillOpacity = parseFloat(annot.getCustomData('fillOpacity'));
                const strokeOpacity = parseFloat(annot.getCustomData('strokeOpacity'));

                const hexToRgb = hex =>
                    hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
                        , (m, r, g, b) => '#' + r + r + g + g + b + b)
                        .substring(1).match(/.{2}/g)
                        .map(x => parseInt(x, 16));

                if (strokeColourHex) {
                    const strokeColour = hexToRgb(strokeColourHex);
                    const [r, g, b] = strokeColour;

                    this.annotManager.setAnnotationStyles(annot, {
                        StrokeColor: new Annotations.Color(r, g, b, strokeOpacity)
                    });
                }

                if (fillColourHex) {
                    const fillColour = hexToRgb(fillColourHex);
                    const [r, g, b] = fillColour;

                    this.annotManager.setAnnotationStyles(annot, {
                        FillColor: new Annotations.Color(r, g, b, fillOpacity)
                    });
                }

                if (stroke) {
                    this.annotManager.setAnnotationStyles(annot, {
                        StrokeThickness: stroke
                    });
                }

                if (textColourHex) {
                    const textColour = hexToRgb(textColourHex);
                    const [r, g, b] = textColour;

                    this.annotManager.setAnnotationStyles(annot, {
                        TextColor: new Annotations.Color(r, g, b, 1)
                    });
                }

                if (textSize) {
                    this.annotManager.setAnnotationStyles(annot, {
                        FontSize: textSize
                    });
                }
            }

            if (measurementDisplayUnits) {
                annot.setCustomData('displayUnit', measurementDisplayUnits);
                annot.adjustRect();
            }

            if (shouldSetMeasurementSign(annot)) {
                this.setAnnotationMeasurementSign(annot, measurementSign);
            }
        });

        this.annotManager.selectAnnotation(annotation);
        this.annotManager.trigger('annotationChanged', [annotations, 'modify', {}]);
    }

    setAnnotationDimensions(annotation, depth, width) {
        if (!annotation
            || !annotation.Measure
            || !annotation.ToolName) {
            return;
        }

        depth = Math.abs(parseFloat(depth));
        if (!depth || isNaN(depth)) {
            depth = 1;
        }

        let sign;
        let factor;

        // Area line needs an extra dimension
        if (annotation.ToolName.includes('PerimeterArea')) {
            factor = annotation.Measure.distance[0].factor;
            sign = Math.sign(factor);
            if (sign === 0) {
                sign = 1;
            }

            annotation.Measure.distance[0].factor = sign * depth;
            annotation.adjustRect();
            return;
        }

        factor = annotation.Measure.area[0].factor;
        sign = Math.sign(factor);
        if (sign === 0) {
            sign = 1;
        }

        annotation.Measure.area[0].factor = sign * depth;

        // Line Volume
        if (annotation.ToolName.includes('PerimeterVolume')) {

            width = Math.abs(parseFloat(width));
            if (!width || isNaN(width)) {
                width = 1;
            }

            annotation.Measure.distance[0].factor = sign * width;
        }

        annotation.adjustRect();
    }

    setAnnotationMeasurementSign(annotation, sign) {
        if (!annotation
            || !annotation.Measure
            || !annotation.ToolName
            || !sign
            || isNaN(sign)
            || sign === 0) {
            return;
        }

        let distanceSign = Math.sign(annotation.Measure.distance[0].factor);
        let areaSign = Math.sign(annotation.Measure.area[0].factor);
        if (distanceSign === 0) {
            distanceSign = 1;
        }
        if (areaSign === 0) {
            areaSign = 1;
        }

        if (distanceSign !== sign) {
            if (distanceSign < 0) {
                distanceSign *= -1;
            } else {
                distanceSign *= sign;
            }
        }

        if (areaSign !== sign) {
            if (areaSign < 0) {
                areaSign *= -1;
            } else {
                areaSign *= sign;
            }
        }

        annotation.Measure.distance[0].factor = distanceSign;
        annotation.Measure.area[0].factor = areaSign;

        // Default styles
        let newFillColor = new Annotations.Color(16, 124, 16, 0.25);
        let newStrokeColor = new Annotations.Color(13, 114, 185);

        if (annotation.InReplyTo) {
            // Part of a group, so inherit the parent's style
            const annotLeader = this.annotManager.getAnnotationById(annotation.InReplyTo);
            if (annotLeader) {
                newFillColor = annotLeader.FillColor ?? newFillColor;
                newStrokeColor = annotLeader.StrokeColor ?? newStrokeColor;
            }
        }

        if (annotation.FillColor) {
            annotation.FillColor = newFillColor;
        }

        if (annotation.StrokeColor) {
            annotation.StrokeColor = newStrokeColor;
        }

        annotation.adjustRect();
    }

    requestDeleteSelectedAnnotation() {
        const selectedAnnotation = this.annotManager.getSelectedAnnotations()[0];
        if (!selectedAnnotation) {
            return;
        }

        this.eventHandler.onAnnotationDeleteRequest(selectedAnnotation);
    }

    deleteAnnotationById(id) {
        if (!id) {
            return;
        }

        const annotation = this.annotManager.getAnnotationById(id);
        if (!annotation) {
            return;
        }

        this.annotManager.selectAnnotation(annotation);
        this.annotManager.deleteAnnotation(annotation);
    }

    duplicateAnnotationsById(ids) {
        if (!ids) {
            return;
        }

        let annotations = [];

        ids.forEach((id) => {
            const annotation = this.annotManager.getAnnotationById(id);
            if (annotation) {
                annotations.push(annotation);
            }
        });

        if (!annotations || annotations.length < 1) {
            return;
        }

        this.annotManager.deselectAllAnnotations();
        this.annotManager.selectAnnotations(annotations);
        this.annotManager.updateCopiedAnnotations();
        this.annotManager.deselectAllAnnotations();
        setTimeout(() => {
            this.annotManager.pasteCopiedAnnotations();
        }, 0);
    }

    selectAnnotationsById(ids) {
        if (!ids || ids.length <= 0) {
            return;
        }

        const annotations = this.annotManager.getAnnotationsList().filter(annot => ids.includes(annot.Id));
        if (!annotations || annotations.length <= 0) {
            return;
        }

        this.annotManager.deselectAllAnnotations();
        this.annotManager.jumpToAnnotation(annotations[0]);

        if (annotations.length === 1 && !annotations[0].isVisible()) {
            // Don't select it
        } else {
            this.annotManager.selectAnnotations(annotations);
        }
    }

    jumpToAnnotation(id) {
        if (!id) {
            return;
        }

        const annotation = this.annotManager.getAnnotationById(id);
        if (!annotation) {
            return;
        }

        this.annotManager.jumpToAnnotation(annotation);
    }

    setAnnotationVisibilityById(id, visible) {
        if (!id) {
            return;
        }

        const annotation = this.annotManager.getAnnotationById(id);
        if (!annotation) {
            return;
        }

        if (visible) {
            this.annotManager.showAnnotation(annotation);
        } else {
            this.annotManager.hideAnnotation(annotation);
        }

        this.annotManager.trigger('annotationChanged', [[annotation], 'modify', {}]);
    }

    setAnnotationVisibilityByIds(ids, visible) {
        if (!ids) {
            return;
        }

        const annotations = this.annotManager.getAnnotationsList().filter(annot => ids.includes(annot.Id));
        if (!annotations || annotations.length <= 0) {
            return;
        }

        if (visible) {
            this.annotManager.showAnnotations(annotations);
        } else {
            this.annotManager.hideAnnotations(annotations);
        }

        this.annotManager.trigger('annotationChanged', [annotations, 'modify', {}]);
    }

    showTooltip(header, text, x, y) {
        this.eventHandler.onShowTooltip(header, text, x, y);
    }

    hideTooltip() {
        this.eventHandler.onHideTooltip();
    }

    getMousePopupPosition(popup, mouseArgs, gap = 20) {
        const { width, height } = popup.getBoundingClientRect();
        let left = mouseArgs.x + gap;
        let top = mouseArgs.y + gap;

        if (left + width > window.innerWidth) {
            left = mouseArgs.x - width - gap;
        }

        if (top + height > window.innerHeight) {
            top = mouseArgs.y - height - gap;
        }

        const x = Math.round(left);
        const y = Math.round(top);

        return { x, y };
    }

    getPopupPosition(popup, annotationPosition, gap = 25) {
        const { width, height } = popup.getBoundingClientRect();
        const { topLeft, bottomRight } = annotationPosition;

        const scrollContainer = this.docViewer.getScrollViewElement();
        const boundingBox = scrollContainer.getBoundingClientRect();
        const visibleRegion = {
            left: boundingBox.left + scrollContainer.scrollLeft,
            right: boundingBox.left + scrollContainer.scrollLeft + boundingBox.width,
            top: boundingBox.top + scrollContainer.scrollTop,
            bottom: boundingBox.top + scrollContainer.scrollTop + boundingBox.height,
        };

        // gap between the annotation selection box and the popup element
        const annotTop = topLeft.y - gap;
        const annotBottom = bottomRight.y + gap;

        let top;
        if (annotBottom + height < visibleRegion.bottom) {
            top = annotBottom;
        } else if (annotTop - height > visibleRegion.top) {
            top = annotTop - height;
        } else {
            // there's no room for it in the vertical axis, so just choose the top of the visible region
            top = visibleRegion.top + 5;
        }

        const center = (topLeft.x + bottomRight.x) / 2 - scrollContainer.scrollLeft;
        let left = center - width / 2;

        if (left < 0) {
            left = 0;
        } else if (left + width > window.innerWidth) {
            left = window.innerWidth - width;
        }

        const y = Math.round(top - scrollContainer.scrollTop);
        const x = Math.max(Math.round(left), 4);

        return { x, y };
    }

    getAnnotationPositionById(id) {
        if (!id) {
            return null;
        }

        const annotation = this.annotManager.getAnnotationById(id);
        if (!annotation) {
            return null;
        }

        return this.getAnnotationPosition(annotation);
    }

    getAnnotationPosition(annotation) {
        const { left, top, right, bottom } = this.getAnnotationPageCoordinates(annotation);

        const pageNumber = annotation.getPageNumber();
        const topLeft = this.convertPageCoordinatesToWindowCoordinates(left, top, pageNumber);
        let bottomRight = this.convertPageCoordinatesToWindowCoordinates(right, bottom, pageNumber);

        const isNote = annotation instanceof Annotations.StickyAnnotation;
        if (isNote) {
            const zoom = this.getZoom();
            const width = bottomRight.x - topLeft.x;
            const height = bottomRight.y - topLeft.y;

            // the visual size of a sticky annotation isn't the same as the rect we get above due to its NoZoom property
            // here we do some calculations to try to make the rect have the same size as what the annotation looks in the canvas
            bottomRight = {
                x: topLeft.x + width / zoom * 1.2,
                y: topLeft.y + height / zoom * 1.2,
            };
        }

        return { topLeft, bottomRight };
    };

    getAnnotationPageCoordinates(annotation) {
        const rect = annotation.getRect();
        let { x1: left, y1: top, x2: right, y2: bottom } = rect;

        const isNote = annotation instanceof Annotations.StickyAnnotation;
        const noteAdjustment = annotation.Width;

        const rotation = this.docViewer.getCompleteRotation(annotation.PageNumber);
        if (rotation === 1) {
            [top, bottom] = [bottom, top];
            if (isNote) {
                top -= noteAdjustment;
                bottom -= noteAdjustment;
            }
        } else if (rotation === 2) {
            [left, right] = [right, left];
            [top, bottom] = [bottom, top];
            if (isNote) {
                top -= noteAdjustment;
                bottom -= noteAdjustment;
                left -= noteAdjustment;
                right -= noteAdjustment;
            }
        } else if (rotation === 3) {
            [left, right] = [right, left];
            if (isNote) {
                left -= noteAdjustment;
                right -= noteAdjustment;
            }
        }

        return { left, top, right, bottom };
    };

    convertPageCoordinatesToWindowCoordinates(x, y, pageNumber) {
        const displayMode = this.docViewer.getDisplayModeManager().getDisplayMode();

        return displayMode.pageToWindow({ x, y }, pageNumber);
    };
}

export async function newPlanViewer(callbackRef, scrollViewElement, documentElement) {
    await loadScript("./js/PlanViewer/lib/core/pdf/PDFNet.js", "Failed to load PDFNet module");
    return new PlanViewer(callbackRef, scrollViewElement, documentElement);
}

const loadScript = (scriptSrc, warning) =>
    new Promise(resolve => {
        if (!scriptSrc) {
            return resolve();
        }

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.onload = function () {
            resolve();
        };
        script.onerror = function () {
            if (warning) {
                console.warn(warning);
            }
            resolve();
        };
        script.src = scriptSrc;
        document.getElementsByTagName('head')[0].appendChild(script);
    });