﻿import { Annotations } from '../globals.js'
import calculateMeasurementContents from '../Helpers/calculateMeasurementContents.js'

class LineAnnotation extends Annotations.LineAnnotation {
    static planViewer;

    constructor() {
        super();
        this.selectionModel.prototype.drawSelectionOutline = Annotations.BoxSelectionModel.prototype.drawSelectionOutline;
    }

    setContents(value) {
        super.setContents(calculateMeasurementContents(this, this.constructor.planViewer.annotManager, value));
    }
}

export default LineAnnotation;