﻿import { Tools } from '../../globals.js'

class PanTool extends Tools.PanTool {
    constructor(planViewer) {
        super(planViewer.docViewer);

        this.planViewer = planViewer;
    }

    keyDown(e) {
        if (e.key === 'Delete') {
            this.planViewer.requestDeleteSelectedAnnotation();
        } else {
            super.keyDown(e);
        }
    }
}

export default PanTool;