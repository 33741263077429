﻿import getAnnotationExportData from '../Helpers/getAnnotationExportData.js';

import { debounce } from '../../Core/Utility/utils';

class EventHandler {
    constructor(planViewer, callbackRef) {
        this.planViewer = planViewer;
        this.callbackRef = callbackRef;

        this.onWheel = this.onWheel.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onDocumentLoaded = this.onDocumentLoaded.bind(this);
        this.onFinishedRendering = this.onFinishedRendering.bind(this);
        this.onToolChange = this.onToolChange.bind(this);
        this.onPageRotate = this.onPageRotate.bind(this);
        this.onPageDelete = this.onPageDelete.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSetScale = this.onPageSetScale.bind(this);
        this.onMouseHover = this.onMouseHover.bind(this);

        this.onAnnotationsChanged = this.onAnnotationsChanged.bind(this);
        this.onAnnotationsSelected = this.onAnnotationsSelected.bind(this);
        this.onAnnotationDeleteRequest = this.onAnnotationDeleteRequest.bind(this);
        this.onAnnotationDoubleClicked = this.onAnnotationDoubleClicked.bind(this);
        this.marshallDirtyAnnotations = this.marshallDirtyAnnotations.bind(this);
        this.onShowTooltip = this.onShowTooltip.bind(this);
        this.onHideTooltip = this.onHideTooltip.bind(this);

        this.marshallDirtyAnnotationsDebounceWrapper = debounce(this.marshallDirtyAnnotations, 100);

        this.planViewer.scrollViewElement.addEventListener('wheel', this.onWheel, { passive: false });
        document.addEventListener('keydown', this.onKeyDown);
        this.planViewer.docViewer.addEventListener('mouseEnter', this.onMouseEnter);
        this.planViewer.docViewer.addEventListener('mouseLeave', this.onMouseLeave);
        this.planViewer.docViewer.addEventListener('documentLoaded', this.onDocumentLoaded);
        this.planViewer.docViewer.addEventListener('finishedRendering', this.onFinishedRendering);

        this.planViewer.docViewer.addEventListener('mouseMove', this.onMouseHover);
        this.planViewer.annotManager.addEventListener('annotationChanged', this.onAnnotationsChanged);
        this.planViewer.annotManager.addEventListener('annotationSelected', this.onAnnotationsSelected);
        this.planViewer.annotManager.addEventListener('annotationDoubleClicked', this.onAnnotationDoubleClicked);
    }

    dispose() {
        this.planViewer.scrollViewElement.removeEventListener('wheel', this.onWheel, { passive: false });
        document.removeEventListener('keydown', this.onKeyDown);
        this.planViewer.docViewer.removeEventListener('mouseEnter', this.onMouseEnter);
        this.planViewer.docViewer.removeEventListener('mouseLeave', this.onMouseLeave);
        this.planViewer.docViewer.removeEventListener('documentLoaded', this.onDocumentLoaded);
        this.planViewer.docViewer.removeEventListener('finishedRendering', this.onFinishedRendering);

        this.planViewer.docViewer.removeEventListener('mouseMove', this.onMouseHover);
        this.planViewer.docViewer.getAnnotationManager().removeEventListener('annotationChanged', this.onAnnotationsChanged);
        this.planViewer.docViewer.getAnnotationManager().removeEventListener('annotationSelected', this.onAnnotationsSelected);
        this.planViewer.docViewer.getAnnotationManager().removeEventListener('annotationDoubleClicked', this.onAnnotationDoubleClicked);

        this.callbackRef.dispose();
    }

    onWheel(e) {
        if (!this.planViewer.inFocus) {
            return;
        }

        e.preventDefault();

        let zoomFactor = this.planViewer.getZoom();

        if (e.deltaY < 0) {
            zoomFactor = Math.min(zoomFactor * 1.25, 99.99);
        } else if (e.deltaY > 0) {
            zoomFactor = Math.max(zoomFactor * 0.8, 0.5);
        }

        this.planViewer.zoomToMouse(zoomFactor);
    }

    onMouseEnter() {
        this.planViewer.inFocus = true;
    }

    onMouseLeave() {
        this.planViewer.inFocus = false;
    }

    onKeyDown(e) {
        if (e.ctrlKey && event.key === 'c') {
            this.planViewer.annotManager.updateCopiedAnnotations();
        }

        if (e.ctrlKey && event.key === 'v') {
            this.planViewer.annotManager.deselectAllAnnotations();

            setTimeout(() => {
                this.planViewer.annotManager.pasteCopiedAnnotations(true);
            }, 0);
        }
    }

    onDocumentLoaded() {
        if (this.planViewer.initialPageRotations) {

            for (const [rotation, pages] of Object.entries(this.planViewer.initialPageRotations)) {
                this.planViewer.docViewer.getDocument().rotatePages(pages, rotation).then(() => {
                    delete this.planViewer.initialPageRotations[rotation];

                    if (this.planViewer.initialPageRotations.length <= 0) {
                        this.planViewer.initialPageRotations = undefined;
                    }
                });
            }
        }

        if (this.planViewer.initialXFDFString) {
            const annotationManager = this.planViewer.annotManager;
            annotationManager.importAnnotations(this.planViewer.initialXFDFString).then(() => {

                let annotationsData = [];
                const annotations = annotationManager.getAnnotationsList();
                annotations.forEach((annotation) => {
                    if (annotation instanceof Annotations.MarkupAnnotation
                        && !annotation.getCustomData("IsScaleAnnotation")) {
                        annotationsData.push(getAnnotationExportData(annotation));
                    }
                });

                this.planViewer.initialXFDFString = undefined;
                this.planViewer.pageScaleHandler.newDocumentLoaded();
                this.callbackRef.invokeMethodAsync("OnDocumentLoadedEvent", { annotations: annotationsData });
            });
        }
        else {
            this.planViewer.initialXFDFString = undefined;
            this.planViewer.pageScaleHandler.newDocumentLoaded();
            this.callbackRef.invokeMethodAsync("OnDocumentLoadedEvent", {annotations: null});
        }
    }

    onFinishedRendering() {
        this.callbackRef.invokeMethodAsync("OnDocumentFinishedRenderingEvent");
    }

    onToolChange(toolName) {
        this.callbackRef.invokeMethodAsync("OnToolChangeEvent", {
            toolName: toolName
        });
    }

    onPageRotate(pageNumber, rotation) {
        this.planViewer.annotManager.deselectAllAnnotations();

        this.callbackRef.invokeMethodAsync("OnPageRotateEvent", {
            pageNumber: pageNumber,
            pageRotation: rotation
        });
    }

    onPageDelete(pageNumber) {
        this.planViewer.annotManager.deselectAllAnnotations();

        this.callbackRef.invokeMethodAsync("OnPageDeleteEvent", {
            pageNumber: pageNumber
        });
    }

    onPageChange(pageNumber, scale) {
        this.planViewer.annotManager.deselectAllAnnotations();

        this.callbackRef.invokeMethodAsync("OnPageChangeEvent", {
            pageNumber: pageNumber,
            scale: scale
        });
    }

    onPageSetScale(setScaleRequest) {
        this.callbackRef.invokeMethodAsync("OnPageSetScaleEvent", setScaleRequest);
    }

    onMouseHover(e) {
        const viewElement = this.planViewer.docViewer.getViewerElement();
        let annotation = this.planViewer.annotManager.getAnnotationByMouseEvent(e);

        if (!this.planViewer.blockHover
            && annotation
            && annotation.Listable
            && viewElement.contains(e.target)) {
            let contents = annotation.getContents();

            const groupedAnnots = this.planViewer.annotManager.getGroupAnnotations(annotation);
            if (groupedAnnots && groupedAnnots.length > 1 && groupedAnnots[0].Measure) {
                let groupLeader;

                groupedAnnots.forEach((childAnnotation) => {
                    if (!childAnnotation.isGrouped()) {
                        groupLeader = childAnnotation;
                    }
                });

                if (groupLeader) {
                    const groupContents = groupLeader.getCustomData('groupContents');
                    if (groupContents) {
                        contents = `This Segment: ${contents}\nTotal: ${groupContents}`;
                    }
                }
            }

            this.planViewer.hoveredAnnotation = annotation;

            this.callbackRef.invokeMethodAsync("OnAnnotationHoverEvent", {
                annotationName: annotation.Subject,
                annotationContents: contents,
                mousePosition: {
                    x: parseFloat(e.clientX),
                    y: parseFloat(e.clientY)
                }
            });
        } else if (this.planViewer.hoveredAnnotation) {
            this.callbackRef.invokeMethodAsync("OnAnnotationHoverEndEvent");
            this.planViewer.hoveredAnnotation = undefined;
        }
    }

    onAnnotationsChanged(annotations, action, info) {
        if (info && (info.imported || info.isUndoRedo)) {

            // Fix styles and toolname mapping
            annotations.forEach((annotation) => {
                const fillOpacity = annotation.getCustomData('fillOpacity');
                if (fillOpacity) {
                    annotation.FillColor.A = parseFloat(fillOpacity);
                }

                const strokeOpacity = annotation.getCustomData('strokeOpacity');
                if (strokeOpacity) {
                    annotation.StrokeColor.A = parseFloat(strokeOpacity);
                }

                const toolName = annotation.getCustomData('toolName');
                if (toolName) {
                    annotation.ToolName = toolName;
                }

                if (!this.planViewer.assertValidAnnotation(annotation)) {
                    return;
                }

                annotation.adjustRect();
            });
        }

        const annotationManager = this.planViewer.annotManager;

        if (action === 'delete') {

            annotations.forEach((annotation) => {
                
                annotationManager.ungroupAnnotations([annotation]);

                if (this.groupEdit) {
                    // First, unlock the group members
                    this.groupEdit.group.forEach(groupAnnot => {
                        groupAnnot.Locked = false;
                    });

                    // If the annotation is the group leader, nuke the group
                    if (this.groupEdit.leader.Id === annotation.Id) {
                        annotationManager.deleteAnnotations(this.groupEdit.group);
                    } else {
                        // Otherwise the group is rebuilt since deleting one unbundles them all
                        const newGroup = this.groupEdit.group.filter(annot => annot.Id != annotation.Id);
                        annotationManager.groupAnnotations(this.groupEdit.leader, newGroup);

                        newGroup.push(this.groupEdit.leader);
                        newGroup.forEach((annot) => {
                            annot.adjustRect();
                        });

                        this.planViewer.annotManager.trigger('annotationChanged', [newGroup, 'modify', {}]);
                        this.planViewer.annotManager.deselectAnnotations(newGroup);
                    }

                    annotation.groupEdit = null;
                }

                this.planViewer.annotManager.deselectAnnotation(annotation);
                this.planViewer.setDirtyAnnotation(action, annotation);
            });
        }

        if (info && (info.imported || info.isUndoRedo)) {
            return;
        }

        if (action === 'modify') {
            let didDoSelect = false;
            annotations.forEach((annotation) => {
                if (annotation.Listable) {
                    this.planViewer.setDirtyAnnotation('modify', annotation);

                    if (annotation.InReplyTo) {
                        this.planViewer.setDirtyAnnotation('modify', this.planViewer.annotManager.getAnnotationById(annotation.InReplyTo));
                    }

                    if (this.planViewer.annotManager.isAnnotationSelected(annotation) && !didDoSelect) {
                        const { topLeft, bottomRight } = this.planViewer.getAnnotationPosition(annotation);

                        this.callbackRef.invokeMethodAsync("OnAnnotationSelectEvent", {
                            annotationId: annotation.Id,
                            annotationPosition: {
                                topLeft: topLeft,
                                bottomRight: bottomRight
                            }
                        });

                        didDoSelect = true;
                    }
                }
            });
        }

        if (action !== 'add') {
            return;
        }

        let didAddAnnotation = false;
        annotations.forEach((annotation) => {

            if (!annotation.ToolName) {
                // Probably copied

                const fillOpacity = annotation.getCustomData('fillOpacity');
                if (fillOpacity) {
                    annotation.FillColor.A = parseFloat(fillOpacity);
                }

                const strokeOpacity = annotation.getCustomData('strokeOpacity');
                if (strokeOpacity) {
                    annotation.StrokeColor.A = parseFloat(strokeOpacity);
                }

                const toolName = annotation.getCustomData('toolName');
                if (toolName) {
                    annotation.ToolName = toolName;
                }

                annotation.adjustRect();
            } else {
                if (annotation.FillColor) {
                    annotation.setCustomData('fillOpacity', annotation.FillColor.A.toString());
                }

                if (annotation.StrokeColor) {
                    annotation.setCustomData('strokeOpacity', annotation.StrokeColor.A.toString());
                }

                if (annotation.ToolName) {
                    annotation.setCustomData('toolName', annotation.ToolName.toString());
                }

                if (!annotation.Listable || annotation.isGrouped()
                    || annotation.SkipName) {
                    return;
                }
            }

            annotation.Locked = false;
            annotation.Subject = this.planViewer.defaultNewAnnotationName;

            this.callbackRef.invokeMethodAsync("OnAnnotationCreatedEvent", {
                annotationPosition: this.planViewer.getAnnotationPosition(annotation),
                annotation: getAnnotationExportData(annotation)
            });

            annotation.Subject = null;

            didAddAnnotation = true;
        });

        if (didAddAnnotation) {
            setTimeout(() => {
                this.planViewer.useDefaultTool();
            }, 0);
        }
    }

    marshallDirtyAnnotations() {
        const args = { annotations: Object.values(this.planViewer.dirtyAnnotations) };
        this.callbackRef.invokeMethodAsync("OnAnnotationsChangedEvent", args);
        this.planViewer.dirtyAnnotations = {};
    }

    onAnnotationsSelected(annotations, action) {
        const selectedAnnotations = this.planViewer.annotManager.getSelectedAnnotations();
        if (action === 'selected' && annotations.length > 0 && selectedAnnotations.length > 0) {
            if (this.planViewer.annotManager.isReadOnlyModeEnabled()) {
                return;
            }

            const selectedAnnotation = annotations[0];
            if (!selectedAnnotation.ToolName || !selectedAnnotation.Subject || !selectedAnnotation.isVisible()) {
                return;
            }

            if (selectedAnnotation == selectedAnnotations[0]) {
                const groupedAnnots = this.planViewer.annotManager.getGroupAnnotations(selectedAnnotation);

                const newGroupEdit = {
                    targets: (annotations.length == 1 ? [selectedAnnotation] : groupedAnnots.filter(annot => annot.getPageNumber() === this.planViewer.getCurrentPage())),
                    leader: null,
                    group: groupedAnnots,
                }

                groupedAnnots.forEach((groupAnnot) => {
                    // This is the group leader
                    if (!groupAnnot.isGrouped()) {
                        newGroupEdit.leader = groupAnnot;
                    }

                    if (newGroupEdit.targets.includes(groupAnnot)) {
                        return;
                    }

                    groupAnnot.Locked = true;
                });

                this.groupEdit = newGroupEdit;
            }

            const { topLeft, bottomRight } = this.planViewer.getAnnotationPosition(selectedAnnotation);

            this.callbackRef.invokeMethodAsync("OnAnnotationSelectEvent", {
                annotationId: selectedAnnotation.Id,
                annotationPosition: {
                    topLeft: topLeft,
                    bottomRight: bottomRight
                }
            });
        } else if (action === 'deselected') {
            if (this.groupEdit) {
                this.groupEdit.group.forEach((groupAnnot) => {
                    groupAnnot.Locked = false;
                });

                this.groupEdit = null;
            }

            this.callbackRef.invokeMethodAsync("OnAnnotationDeselectEvent");
        }
    }

    onAnnotationDeleteRequest(annotation) {
        const isGroupLeader = !annotation.isGrouped()
            && this.planViewer.annotManager.getGroupAnnotations(annotation).length > 1;

        this.callbackRef.invokeMethodAsync("OnAnnotationDeleteRequestEvent", {
            annotationId: annotation.Id,
            annotationPosition: this.planViewer.getAnnotationPosition(annotation),
            isGroupLeader: isGroupLeader,
        });
    }

    onAnnotationDoubleClicked(annotation) {
    }

    onShowTooltip(header, text, x, y) {
        this.callbackRef.invokeMethodAsync("OnTooltipEvent", {
            header: header,
            text: text,
            position: {
                x: parseFloat(x),
                y: parseFloat(y)
            }
        });
    }

    onHideTooltip() {
        this.callbackRef.invokeMethodAsync("OnTooltipEndEvent");
    }
}

export default EventHandler;