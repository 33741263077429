﻿import { defaultToolScale, defaultToolPrecision, defaultToolDisplayUnit, PRECISION_MAP, MEASUREMENT_TOOL_NAMES, Annotations } from '../globals.js';

class PageScaleHandler {
    constructor(planViewer) {
        this.planViewer = planViewer;

        this.newDocumentLoaded = this.newDocumentLoaded.bind(this);
        this.onPageChange = this.onPageChange.bind(this);

        this.planViewer.docViewer.addEventListener('pageNumberUpdated', this.onPageChange);
    }

    dispose() {
        this.planViewer.docViewer.removeEventListener('pageNumberUpdated', this.onPageChange);
        this.planViewer = undefined;
    }

    newDocumentLoaded() {
        this.scales = {};
        this.pendingScales = {};
        this.onPageChange(this.planViewer.getCurrentPage());
    }

    newPageScale(scaleAnnotation) {
        if (scaleAnnotation.ToolName !== 'AnnotationSetScaleMeasurement') {
            return;
        }

        const currentPage = scaleAnnotation.getPageNumber();
        const currentDistance = parseFloat(scaleAnnotation.getContents().replace(/,/g, ''));
        const currentUnit = scaleAnnotation.Scale[1][1];

        this.pendingScales[currentPage] = scaleAnnotation;

        this.planViewer.eventHandler.onPageSetScale({
            pageNumber: currentPage,
            scaleDistance: currentDistance,
            scaleUnits: currentUnit,
            displayUnits: defaultToolDisplayUnit,
            annotationId: scaleAnnotation.Id,
            annotationPosition: this.planViewer.getAnnotationPosition(scaleAnnotation)
        });
    }

    setPageScale(pageNumber, newDistance, newUnits, displayUnit) {
        const scaleAnnotation = this.pendingScales[pageNumber];
        if (!scaleAnnotation) {
            return;
        }

        this.pendingScales[pageNumber] = undefined;

        if (scaleAnnotation.ToolName !== 'AnnotationSetScaleMeasurement') {
            return;
        }

        // Cancelled
        if (!newDistance) {
            this.planViewer.annotManager.deleteAnnotation(scaleAnnotation);
            return;
        }

        const newScale = this.calculateNewScale(scaleAnnotation, newDistance, newUnits);
        const correctedNewScale = this.handleLossOfPrecision(scaleAnnotation, newScale, newDistance, newUnits);

        scaleAnnotation.Scale = correctedNewScale;
        scaleAnnotation.Precision = defaultToolPrecision;

        const existingScaleAnnotations = this.planViewer.annotManager
            .getAnnotationsList()
            .filter(annot => annot.getPageNumber() === scaleAnnotation.getPageNumber()
                && annot.getCustomData('IsScaleAnnotation') === 'true'
                && annot != scaleAnnotation);

        if (existingScaleAnnotations) {
            this.planViewer.annotManager.deleteAnnotations(existingScaleAnnotations);
        }

        scaleAnnotation.Hidden = true;
        scaleAnnotation.Listable = false;
        scaleAnnotation.setCustomData('IsScaleAnnotation', "true");
        scaleAnnotation.setCustomData('defaultDisplayUnit', displayUnit);
        this.planViewer.annotManager.redrawAnnotation(scaleAnnotation);

        this.planViewer.useDefaultTool();

        this.scales[scaleAnnotation.getPageNumber()] = scaleAnnotation;
        this.onPageChange(scaleAnnotation.getPageNumber());
    }

    setPageScaleRatio(pageNumber, ratio) {
        if (this.pendingScales[pageNumber]) {
            return;
        }

        const measureDict = JSON.parse(JSON.stringify(this.planViewer.docViewer.getTool('AnnotationSetScaleMeasurement').Measure));

        const scaleAnnotation = new Annotations.LineAnnotation();
        scaleAnnotation.ToolName = 'AnnotationSetScaleMeasurement';
        scaleAnnotation.setStartStyle(Annotations.LineEndType.BUTT);
        scaleAnnotation.setEndStyle(Annotations.LineEndType.BUTT);
        scaleAnnotation.caption = 'no';
        scaleAnnotation.IT = 'LineDimension';
        scaleAnnotation.Measure = measureDict;
        scaleAnnotation.Listable = false;
        scaleAnnotation.setLineLength(100 / scaleAnnotation.Measure.axis[0].factor);
        scaleAnnotation.adjustRect();
        ratio = (ratio * 100) / scaleAnnotation.Scale[1][0];

        this.planViewer.annotManager.addAnnotation(scaleAnnotation);
        this.pendingScales[pageNumber] = scaleAnnotation;
        this.setPageScale(pageNumber, ratio, scaleAnnotation.Scale[1][1], defaultToolDisplayUnit);
    }

    setPageScaleUnit(pageNumber, units) {
        const scale = this.scales[pageNumber];
        if (!scale) {
            return;
        }

        if (!units) {
            return;
        }

        scale.Scale = [
            [scale.Scale[0][0], units],
            [scale.Scale[1][0], units],
        ];
        this.scales[pageNumber] = scale;
        this.onPageChange(pageNumber);
    }

    setPageScaleDisplayUnits(pageNumber, units) {
        const scale = this.scales[pageNumber];
        if (!scale) {
            return;
        }

        if (!units) {
            units = scale.Scale[1][1];
        }

        scale.setCustomData('defaultDisplayUnit', units);
        this.scales[pageNumber] = scale;
        this.onPageChange(pageNumber);
    }

    onPageChange(pageNumber) {
        let pageScaleAnnotation = this.scales[pageNumber];
        if (!pageScaleAnnotation) {
            const existingScaleAnnotations = this.planViewer.annotManager
                .getAnnotationsList()
                .filter(annot => annot.getPageNumber() === pageNumber
                    && annot.getCustomData('IsScaleAnnotation') === 'true');

            if (existingScaleAnnotations) {
                pageScaleAnnotation = existingScaleAnnotations[0];
                this.scales[pageNumber] = pageScaleAnnotation;
            }
        }

        let scale = defaultToolScale;
        let defaultDisplayUnit = scale[1][1];
        let precision = defaultToolPrecision;
        let scaleSet = false;

        if (pageScaleAnnotation) {
            scale = pageScaleAnnotation.Scale;
            precision = pageScaleAnnotation.Precision;
            defaultDisplayUnit = pageScaleAnnotation.getCustomData('defaultDisplayUnit') ?? scale[1][1];
            scaleSet = true;
        }

        let tools = [];
        tools = MEASUREMENT_TOOL_NAMES.map((t) => this.planViewer.getTool(t));

        tools.forEach(tool => {
            if (tool.setStyles && tool.name !== 'Pan') {
                tool.setStyles({
                    Scale: scale,
                    Precision: precision,
                    DefaultDisplayUnit: defaultDisplayUnit
                });
            }
        });

        const convertedScale = {
            from: {
                value: scale[0][0],
                units: scale[0][1],
            },
            to: {
                value: scale[1][0],
                units: scale[1][1],
            },
            precision: PRECISION_MAP[precision],
            displayUnits: defaultDisplayUnit,
            set: scaleSet
        }

        this.planViewer.eventHandler.onPageChange(pageNumber, convertedScale);
    }

    calculateNewScale = (scaleAnnotation, newDistance, newUnits) => {
        let currentScale = scaleAnnotation.Scale;
        let newScale;
        if (currentScale[1][1] != newUnits || currentScale[0][1] != newUnits) {
            // Just set the units first to stop any errors converting between units
            newScale = [
                [currentScale[0][0], newUnits],
                [currentScale[1][0], newUnits],
            ];
            scaleAnnotation.Scale = newScale;
            currentScale = scaleAnnotation.Scale;
        }

        const currentDistance = parseFloat(scaleAnnotation.getContents().replace(/,/g, ''));
        const ratio = newDistance / currentDistance;
        newScale = [
            [currentScale[0][0], currentScale[0][1]],
            [currentScale[1][0] * ratio, currentScale[1][1]],
        ];

        return newScale;
    }

    handleLossOfPrecision = (scaleAnnotation, newScale, newDistance, newUnits) => {
        scaleAnnotation.Scale = newScale;
        return this.calculateNewScale(scaleAnnotation, newDistance, newUnits);
    }
}

export default PageScaleHandler;