﻿export default function calculateMeasurementUnitsFromScale(annotation, units) {
    if (!annotation || !annotation.Scale) {
        return null;
    }

    if (!units) {
        units = annotation.Scale[1][1];
    }

    if (!annotation.ToolName) {
        return units;
    }

    if (annotation.ToolName.includes('Area') && !units.endsWith('2')) {
        units += '2';
    } else if (annotation.ToolName.includes('Volume') && !units.endsWith('3')) {
        units += '3';
    }

    return units;
}