﻿import { Tools } from '../../globals.js'
import LineAnnotation from '../../Annotations/lineAnnotation.js';
import BaseTool from '../BaseTool/base-tool.js';

class DistanceCreateTool extends BaseTool(Tools.DistanceMeasurementCreateTool) {
    constructor(planViewer) {
        super(planViewer);

        LineAnnotation.planViewer = this.planViewer;
        this.annotationPrototype = LineAnnotation;

        this.setDrawMode(Tools.LineCreateTool.DrawModes.TWO_CLICKS);
    }
}

export default DistanceCreateTool;