﻿import { Tools } from '../../globals.js'
import EllipseAnnotation from '../../Annotations/ellipseAnnotation.js';
import BaseTool from '../BaseTool/base-tool.js';

class EllipsesAreaCreateTool extends BaseTool(Tools.EllipseMeasurementCreateTool) {
    constructor(planViewer) {
        super(planViewer);

        EllipseAnnotation.planViewer = this.planViewer;
        this.annotationPrototype = EllipseAnnotation;

        this.setDrawMode(Tools.EllipseCreateTool.DrawModes.TWO_CLICKS);
    }
}

export default EllipsesAreaCreateTool;