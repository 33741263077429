﻿import parseMeasurementContents from './parseMeasurementContents.js'
import calculateMeasurementUnitsFromScale from './calculateMeasurementUnitsFromScale.js';
import { PRECISION_MAP } from '../globals.js';

import configureMeasurements from 'convert-units';
import volume from 'convert-units/lib/cjs/definitions/volume';
import area from 'convert-units/lib/cjs/definitions/area';
import length from 'convert-units/lib/cjs/definitions/length';

const convert = configureMeasurements({
    volume,
    area,
    length,
});

export default function calculateMeasurementContents(annotation, annotManager, value) {
    if (!value) {
        return value;
    }

    if (!annotation.Listable) {
        return value;
    }

    let parsedContents = parseMeasurementContents(value);
    if (!parsedContents) {
        return value;
    }

    if (!annotation.ToolName) {
        return value;
    }

    let units = calculateMeasurementUnitsFromScale(annotation);

    if (annotation.ToolName.includes('PerimeterVolume')
        && annotation.Measure) {
        const factor = annotation.Measure.area[0].factor;
        let sign = Math.sign(factor);
        if (sign === 0) {
            sign = 1;
        }
        parsedContents *= factor * sign;
    }

    const preConvertedContents = parsedContents;
    annotation.setCustomData('measurement', preConvertedContents.toString()); // Actual measurement in scale units

    if (units) {
        const displayUnits = annotation.getCustomData('displayUnit');
        if (displayUnits && displayUnits !== units) {
            const convertedContents = convert(parsedContents).from(units).to(displayUnits);
            parsedContents = convertedContents;
            units = displayUnits;
        }

        units = ' ' + units;
    }
    else {
        units = '';
    }

    let precision = 0;
    if (annotation.Precision) {
        precision = PRECISION_MAP[annotation.Precision]
    }

    if (annotation.Listable) {
        const groupAnnots = annotManager.getGroupAnnotations(annotation);
        if (groupAnnots && groupAnnots.length > 1) {
            let groupLeader;
            let convertedTotal = parsedContents;
            let actualTotal = preConvertedContents;
            let pages = [];

            groupAnnots.forEach((groupedAnnot) => {
                if (!groupedAnnot.isGrouped()) {
                    groupLeader = groupedAnnot;
                }

                if (pages.indexOf(groupedAnnot.getPageNumber()) === -1) {
                    pages.push(groupedAnnot.getPageNumber());
                }

                if (groupedAnnot === annotation) {
                    return; // Continue because we've already added our value
                }

                let measurement = parseFloat(groupedAnnot.getCustomData('measurement'));
                if (!measurement || isNaN(measurement)) {
                    measurement = 0;
                }

                actualTotal += measurement;
                convertedTotal += parseMeasurementContents(groupedAnnot.getContents());
            });

            if (groupLeader) {
                const groupContents = convertedTotal
                    .toLocaleString(undefined, { maximumFractionDigits: precision })
                    + units;

                groupLeader.setCustomData('groupContents', groupContents.toString()); // Converted string version
                groupLeader.setCustomData('groupMeasurement', actualTotal.toString()); // Actual measurement in scale units
                groupLeader.setCustomData('groupIsMultiPage', (pages.length > 1 ? "true" : "false"));
            }
        }
    }

    const contents = parsedContents
        .toLocaleString(undefined, { maximumFractionDigits: precision })
        + units;

    return contents;
}