﻿import { Annotations } from '../globals.js'

class CountAnnotation extends Annotations.EllipseAnnotation {
    static planViewer;

    constructor() {
        super();
    }

    setContents(value) {

        if (this.Listable) {
            const groupAnnots = this.constructor.planViewer.annotManager.getGroupAnnotations(this);
            if (groupAnnots) {
                let groupLeader;
                let pages = [];
                const count = groupAnnots.length.toString();

                groupAnnots.forEach((groupedAnnot) => {
                    if (!groupedAnnot.isGrouped()) {
                        groupLeader = groupedAnnot;
                    }

                    if (pages.indexOf(groupedAnnot.getPageNumber()) === -1) {
                        pages.push(groupedAnnot.getPageNumber());
                    }
                });

                if (groupLeader) {
                    groupLeader.setCustomData('groupContents', count);
                    groupLeader.setCustomData('groupMeasurement', count);
                    groupLeader.setCustomData('groupIsMultiPage', (pages.length > 1 ? "true" : "false"));
                }

                super.setContents(count);
                return;
            }
        }

        super.setContents(value);
    }

    adjustRect() {
        this.setContents("1");
        super.adjustRect();
    }
}

export default CountAnnotation;